document.addEventListener("turbolinks:load", function() {
  var recapthaForm = $('form[data-grecaptcha-sitekey]:first');
  if (recapthaForm.length) {
    const recapthaSiteKey = recapthaForm.data('grecaptcha-sitekey');
    if (recapthaSiteKey.length > 0) {
      $.getScript('https://www.google.com/recaptcha/api.js?render=' + recapthaSiteKey, function () {
      });
      $('form[data-grecaptcha-sitekey]').each(function () {
        $('<input>').attr('type', 'hidden').attr('name', 'g-recaptcha-response').appendTo($(this))
        $(this).on('submit', function (e) {
          e.preventDefault();
          if ($(e.currentTarget).hasClass('needs-validation')) {
            if (e.currentTarget.checkValidity() === false) {
              return false
            }
          }
          grecaptcha.ready(function() {
            try {
              grecaptcha.execute(recapthaSiteKey, { action: 'submit' }).then(function(token) {
                e.currentTarget.querySelector("[name='g-recaptcha-response']").value = token
                if ($(e.currentTarget).data('remote')) { // workaround for ajax forms
                  Rails.handleRemote.call(e.currentTarget, e)
                } else {
                  e.currentTarget.submit();
                }
              })
            } catch {
              const errorBlock = $('<div id="message_block">' +
                  '<div class="alert alert-danger fade alert-dismissible show">' +
                  '<ul class="ul-disc ps-3 mb-0"><li>Captcha error. Please try again</li></ul>' +
                  '</div></div>')
              $(e.currentTarget).before(errorBlock)
            }
          });
          return false
        });
      });
    }
  }
});
